import React, { Component } from "react"
import { ContactForm, Layout } from "../../../components"
import { Helmet } from "react-helmet"
import { Cubes, H2, Medailon, PaddingWrapper } from "@igloonet-web/shared-ui"
import Logo from "../../../images/reference/helcel/logo.png"
import Web from "../../../images/reference/helcel/helcel-web.png"
import Martin from "../../../images/reference/helcel/martin.jpg"
import Klaudie from "../../../images/team/mini/klaudie.jpg"
import Lukas from "../../../images/team/mini/lukas.jpg"
import Ads from "../../../images/reference/helcel/ads.png"
import Graf from "../../../images/reference/helcel/graf.png"
import ProjectManagement from "../../../images/reference/helcel/project_management.jpg"
import ReferenceHeader from "../../../components/reference-detail/reference-header"
import ReferenceImageText from "../../../components/reference-detail/reference-image-text"

class Helcel extends Component {
  constructor({ props }) {
    super(props)
  }

  render() {
    return (
      <Layout>
        <Helmet>
          <title>
            Reference na marketingové práce pro HELCEL nábytek | igloonet
          </title>
          <meta
            name="description"
            content="Jak se nám povedlo snížit náklady za půl roku na reklamu a přitom zvýšit tržby ze spravovaných kanálů?"
          />
        </Helmet>
        <ReferenceHeader
          companyName="HELCEL NÁBYTEK"
          heading="Převzali jsme kampaně a zlepšili jejich efektivitu o 19,52 %"
          date="pohled na leden–červen 2018"
        />
        <ReferenceImageText
          halfImgSize
          right
          img={Web}
          eager
          alt=""
          logo={Logo}
          logoAlt="logo společnosti Helcel"
          link="//www.nabytek-helcel.cz/"
        >
          <p>
            Rodinná společnost HELCEL Nábytek působí ve svém oboru už přes 25
            let. Na e-shopu i v pěti prodejnách nabízí vše od sedacích souprav,
            obývacích stěn a postelí až po kuchyňské linky, dětské pokoje a
            bytové doplňky.
          </p>
        </ReferenceImageText>

        <ReferenceImageText>
          <h2>Jak jsme postupovali</h2>
          <p>
            Spolupráci jsme odstartovali na konci roku 2017 analýzou a
            zhodnocením dosavadních kampaní ve vyhledávání Google a Seznam,
            zaměřili jsme se přitom na jejich efektivitu a hledání dalšího
            potenciálu.
          </p>
          <p>
            Začátkem roku 2018 jsme navázali intenzivní prací na úpravě
            reklamních účtů. Kromě vyhledávačů jsme si posvítili také na
            výkonnostní kampaně na Facebooku. Za půl roku spolupráce jsme při
            rozsáhlé struktuře propagace dokázali{" "}
            <strong>
              snížit náklady na reklamu a přitom zvýšit tržby ze spravovaných
              kanálů
            </strong>
            .
          </p>
          <p>
            Období prvního půl roku spolupráce jsme pro zhodnocení zvolili z
            několika důvodů. Jde mj. o úsek nekřivený obecně velmi silnou
            předvánoční dobou či naopak slabším létem. Od srpna navíc vstupuje
            do hry také redesign webu znemožňující jednoznačné meziroční
            porovnání kampaní.
          </p>
          <Cubes
            data={[
              {
                description: "Snížení nákladů",
                number: "-8,7 %",
              },
              {
                description: "Zvýšení tržeb",
                number: "+13,4 %",
              },
              {
                description: "Snížení PNO",
                number: "-19,5 %",
              },
            ]}
          />
        </ReferenceImageText>
        <ReferenceImageText>
          <H2>Cíle počátku spolupráce</H2>
          <p>
            Díky auditu PPC systémů jsme sice odhalili reálný potenciál na
            zlepšení výkonnosti kampaní, naším hlavním cílem ale bylo po domluvě
            s klientem <strong>jasné a stabilní projektové řízení</strong>. Bez
            něj si ostatně dosahování stanovených cílů a plynulé plnění plánu
            prací nedokážeme představit. Právě stabilní řízení v předchozí
            spolupráci chybělo.
          </p>
          <h3 className="pt-5 pb-3">Dílčí cíle</h3>
          <ul>
            <li>
              Meziroční růst tržeb při <strong>snížení PNO</strong> u všech
              spravovaných kanálů (počítáno pro last non-direct click atribuci v
              Google Analytics).
            </li>
            <li>
              <strong>Zvýšení míry automatizace</strong> tvorby a správy
              kampaní.
            </li>
          </ul>
        </ReferenceImageText>
        <ReferenceImageText
          halfImgSize
          img={ProjectManagement}
          responsiveTextOrder
          alt=""
        >
          <h2>Stabilní projektové řízení</h2>
          <p>
            Pokud pomineme úvodní nastavování strategie, průběžnou úpravu
            vybraných taktik či obvyklou ad hoc komunikaci, pak gró projektového
            řízení zajišťujeme skrze:
          </p>
          <ul>
            <li className="pb-4">
              <strong>Zesílení dohledu nad celým projektem</strong> – o HELCEL
              Nábytek se od počátku stará stále stejná zkušená projektová
              vedoucí <a href="/klaudie">Klaudie</a>, kontrolu řízení, stabilitu
              a rozvoj pak posiluje i vedoucí týmu <a href="/lukas">Lukáš</a>.
              Oba v igloonetu navíc zastávají pozice garantů PPC systémů a
              sociálních sítí.
            </li>
            <li className="pb-4">
              <strong>Pravidelné projektové schůzky</strong> – kromě běžné
              každodenní komunikace probíhá nad projektem i měsíční schůzka, kde
              řešíme výsledky, určujeme priority a plánujeme další postup.
              Pravidelně, byť s nižší frekvencí, se scházíme také s klientem.
              Každá schůzka má svůj zápis a výstupem jsou mj. úkoly pro
              konkrétní lidi.
            </li>
            <li className="pb-4">
              <strong>
                Využití systému na řízení projektů Redmine <sup>1</sup>
              </strong>{" "}
              – díky němu dokážeme efektivně řešit velké i drobné úkoly.
              Nehrozí, že na nějaký zapomeneme, a máme přehled nad jejich
              plněním. Do systému má přístup také klient.
            </li>
            <li className="pb-4">
              <strong>Systém checklistů</strong> – pravidelné, opakující se
              práce jsou součástí checklistů. Víme tedy, co už jsme zvládli, co
              je potřeba udělat a co ideálně automatizovat.
            </li>
          </ul>
          <p>
            Jde o výčet několika hlavních postupů, které nám pomáhají (nejen
            tento) projekt řídit <strong>přesně a ve vysokém standardu</strong>.
            Zásadní je pro klienta udržet kontinuitu řízení, aby nemusel s
            různými lidmi opakovaně řešit stále stejné situace.
          </p>
        </ReferenceImageText>
        <PaddingWrapper>
          <Medailon personName="Martin Helcel" position="jednatel" img={Martin}>
            <p>
              Požadavek na stabilní projektové řízení byl ze strany igloonetu
              splněn na 100 %. Považuji za velkou výhodu, že na projektu pracují
              stabilně stejní lidé, a vzájemně tak vždy víme, jaké jsou naše
              cíle. Velmi také oceňuji postřehy k možnému vylepšení celého
              projektu.
            </p>
          </Medailon>
        </PaddingWrapper>
        <ReferenceImageText halfImgSize right img={Ads} alt="">
          <h2>Google Ads a Seznam Sklik</h2>
          <p>
            V kontextu cílů jsme se zaměřili zejména na výkonnostní kampaně ve
            vyhledávání a produktovou inzerci v Google Nákupech a Sklik
            Produktových inzerátech.
          </p>
          <p>
            Při zvyšování efektivity kampaní, resp. snižování PNO, si vždy{" "}
            <strong>
              dáváme velký pozor na možný negativní vliv na transakce
            </strong>
            , potažmo tržby (jak pro dané kanály, tak pro celý e-shop). Díky
            promyšleným změnám jsme však při snížení nákladů dokázali tržby z
            kanálů naopak zvýšit, a to o téměř 14 %.{" "}
            <strong>Negativní vliv na e-shop se neobjevil, ba naopak</strong>.
          </p>
          <p>
            Nutno podotknout, že přebírané kampaně nebyly v základu nastaveny
            špatně. Přesto jsme dokázali výrazně zvýšit jejich potenciál.
          </p>
          <Cubes
            data={[
              {
                description: "Snížení nákladů",
                number: "-2,7 %",
              },
              {
                description: "Zvýšení tržeb",
                number: "+13,9 %",
              },
              {
                description: "Snížení PNO",
                number: "-14,6 %",
              },
            ]}
          />
        </ReferenceImageText>
        <ReferenceImageText>
          <H2>Za pozitivním zlepšením stojí zejména</H2>
          <ul>
            <li>větší důraz na akvizici nových zákazníků</li>
            <li>využití automatického biddingu</li>
            <li>rozšíření pokrytí inzerce o další relevantní klíčová slova</li>
            <li>zefektivnění nabízených CPC</li>
            <li>zefektivnění práce se segmenty uživatelů</li>
          </ul>
          <p>
            Přestože jsme náklady spíše snižovali a{" "}
            <strong>konkurence v segmentu meziročně rostla</strong>, dokázali
            jsme přivést o 26,5 % nových uživatelů více.
          </p>
          <p>
            Celkové PNO pro tyto kanály za zvolené období kleslo o 14,63 % při
            růstu tržeb o 13,92 %.{" "}
            <strong>Vytyčeného dílčího cíle jsme tak dosáhli</strong>.
          </p>
        </ReferenceImageText>
        <Medailon
          personName="Klaudie Semelová"
          position="PPC specialistka, vedoucí projektu"
          img={Klaudie}
        >
          <p>
            HELCEL nábytek je klientem snů každého specialisty. Jeho silné
            zázemí a samotná specifika oboru nám poskytují obrovský prostor pro
            aplikaci nových přístupů, postupů, nápadů a automatizací, díky
            kterým jsme schopni kampaně dále posouvat.
          </p>
        </Medailon>
        <ReferenceImageText halfImgSize img={Graf} responsiveTextOrder alt="">
          <h2>Facebook</h2>
          <p>
            Facebookové kampaně nebyly součástí původního auditu, jejich analýza
            a následné úpravy probíhaly až od začátku ledna 2018.
          </p>
          <p>
            Zaměřili jsme se zejména na výkonnostní dynamické kampaně, které
            dokážou efektivně oslovovat uživatele, co v různé míře projevili
            zájem o produkty na e-shopu. Jejich síla je mimo jiné v
            personalizaci. Tento typ kampaní se používal i v předchozím období,{" "}
            <strong>pod námi však prodělal velké změny</strong>.
          </p>
          <p>
            Při tvorbě strategie a taktik jsme se opět zaměřili na snižování
            nákladů při udržení současného výkonu. Ideálně jsme ho chtěli ještě
            vylepšit.
          </p>
          <Cubes
            data={[
              {
                description: "Snížení nákladů",
                number: "-44,9 %",
              },
              {
                description: "Zvýšení tržeb",
                number: "+9,0 %",
              },
              {
                description: "Snížení PNO",
                number: "-49,5 %",
              },
            ]}
          />
        </ReferenceImageText>
        <ReferenceImageText halfImgSize>
          <H2>Za pozitivním zlepšením stojí zejména</H2>
          <ul>
            <li>
              optimalizace kampaní na různé akce v závislosti na cestě
              uživatele, nikoliv pouze na prokliky
            </li>
            <li>rozdělení struktury kampaní dle výkonu kategorií zboží</li>
            <li>rozdělení struktury kampaní dle výkonnosti umístění reklam</li>
            <li>zapojení Instagramu</li>
            <li>rozličnější využití štítků v reklamě, zpestření komunikace</li>
          </ul>
        </ReferenceImageText>
        <Medailon
          personName="Lukáš Havlík"
          position="Vedoucí marketingového týmu"
          img={Lukas}
        >
          <p>
            Volba našeho postupu vycházela zejména ze zkušeností a znalostí
            daných kanálů a jejich možností. Velký benefit vnímám zejména ve
            značném ušetření nákladů, které tak můžeme využít na další
            propagaci. Třeba právě na přivedení nových, potenciálních zákazníků.
          </p>
        </Medailon>
        <ReferenceImageText halfImgSize>
          <p>
            Optimalizace na akce a rozdělení struktury dle kategorií přináší
            mimo jiné lepší schopnost reakce na poptávku během roku a přesnější
            kontrolu nad výkonem kampaní a náklady. Rozmělnění umístění otevírá
            prostor k pestřejší a zajímavější komunikaci s publiky.
          </p>
          <p>
            Z grafů je kromě obecného zlepšení patrná také{" "}
            <strong>stabilizace výkonu celé struktury</strong>. Výsledky mohly
            být teoreticky ještě lepší, z počátku roku jsme však chtěli udržet
            určitou kontinuitu kampaní, aby nedošlo k výpadku.
          </p>
          <p>
            Celkové PNO pro tyto kanály za zvolené období kleslo o 49,47 % při
            růstu tržeb o 8,98 %.{" "}
            <strong>Vytyčeného dílčího cíle jsme tak dosáhli</strong>.
          </p>
        </ReferenceImageText>
        <ReferenceImageText halfImgSize>
          <H2>Závěr a další postup</H2>
          <p>
            Splnili jsme a dále plníme hlavní cíl:{" "}
            <strong>jasné a silně stabilní projektové řízení</strong>. O projekt
            se od počátku stará stále stejná zkušená projektová vedoucí,
            kontrolu řízení, stabilitu a rozvoj projektu pak posiluje také
            vedoucí týmu. Oba zabezpečují kontinuitu projektu, pořádají
            pravidelné projektové schůzky, využívají mj. Redmine a checklisty.
          </p>
          <p>
            Díky správným a promyšleným zásahům a změnám v reklamních účtech
            jsme splnili i druhý, dílčí cíl.{" "}
            <strong>
              Celkové PNO za všechny zmíněné placené kanály kleslo za první
              půlrok spolupráce o 19,52 % při růstu tržeb o 13,42 %
            </strong>
            .
          </p>
          <h3 className="pt-5 pb-3">
            Kromě běžných automatizací nám se správou účtů pomáhá mj.
          </h3>
          <ul>
            <li>
              vlastní nástroj na tvorbu reklam, díky kterému jsou textace vždy
              aktuální
            </li>
            <li>smart bidding pro efektivní řízení rozpočtů</li>
            <li>denní hlídání anomálií a trendů</li>
            <li>denní hlídání spokojenosti zákazníků</li>
          </ul>
          <p>
            <strong>Poslední dílčí cíl proto bereme také za splněný</strong>. V
            automatizaci samozřejmě pokračujeme dál.
          </p>
          <p>
            Po úvodní úpravě a správě primárně výkonnostních kampaních musíme v
            dalších krocích ještě víc přemýšlet nad{" "}
            <strong>širšími souvislostmi</strong>. Vyšší výnosy a ušetřené
            náklady reinvestujeme do dalších relevantních kanálů i publik v
            různých fázích nákupního procesu tak, abychom udrželi růst e-shopu a
            celé firmy.
          </p>
        </ReferenceImageText>
        <Medailon personName="Martin Helcel" position="Jednatel" img={Martin}>
          <p>
            Lidé v igloonetu svědomitě plní všechny dohodnuté cíle, za což jim
            patří velké díky. Za nás zcela jistě nejlepší agentura, se kterou
            jsme kdy spolupracovali.
          </p>
        </Medailon>
        <ReferenceImageText halfImgSize>
          <h3 className="pt-5 pb-3">Dále tedy s klientem spolupracujeme na</h3>
          <ul>
            <li>
              správě nákupních galerií Favi a Biano, které postupně rostou na
              síle,
            </li>
            <li>
              broadových a brandových kampaních na nová publika s různým
              potenciálem k nákupu (sociální a obsahové sítě, RTB…),
            </li>
            <li>
              akvizici nových zákazníků pomocí obsahových sítí a Gmail kampaní,
            </li>
            <li>
              obecnějších remarektingových kampaních na chladnější publika a
              retenci,
            </li>
            <li>propagaci kamenných prodejen,</li>
            <li>kampaních v zahraničí,</li>
            <li>zpřesňování analytiky.</li>
          </ul>
          <p>
            Tento celkový marketingový mix vede, jak nyní pozorujeme v datech, k{" "}
            <strong>
              meziročně velmi výrazným nárůstům tržeb a zisků v podzimním a
              zimním období
            </strong>
            , tedy v hlavní sezóně nábytkářů.
          </p>
        </ReferenceImageText>
        <ContactForm contact="adam" />
      </Layout>
    )
  }
}

export default Helcel
